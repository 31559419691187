import * as React from "react";
import { FC, memo, useEffect, useState } from "react";
//
import NumberInput from "../form/NumberInput";
import Checkbox from "../form/Checkbox";
import Select from "../form/Select";
//
import css from "./style.module.scss";

const levelOfUnderstandingOptions = [
  {
    label: "I'm 100% sure how to do this.",
    value: 1,
  },
  {
    label: "I know how to do most of it.",
    value: 1.5,
  },
  {
    label: "My confusion is visible.",
    value: 2,
  },
  {
    label: "I have no idea what I'm doing.",
    value: 3,
  },
];

const getEstimateLabel = (estimate) => {
  if (estimate === 69) {
    return "Noice.";
  }

  if (estimate === 420) {
    return "Blaze it!";
  }

  return null;
};

const Calculator: FC<{}> = () => {
  const [initialEstimate, setInitialEstimate] = useState(10);
  const [levelOfUnderstanding, setLevelOfUnderstanding] = useState(1);
  const [haveDocs, setHaveDocs] = useState(false);
  const [readDocs, setReadDocs] = useState(false);
  const [forgotTesting, setForgotTesting] = useState(false);
  const [review, setReview] = useState(false);
  const [forgotSomething, setForgotSomething] = useState(true);

  useEffect(() => {
    if (!haveDocs) {
      setReadDocs(false);
    }
  }, [haveDocs, readDocs]);

  const reset = () => {
    setInitialEstimate(10);
    setLevelOfUnderstanding(1);
    setHaveDocs(false);
    setReadDocs(false);
    setForgotTesting(false);
    setReview(false);
    setForgotSomething(true);
  };

  const finalEstimate = (() => {
    let estimate = initialEstimate;

    if (!haveDocs) {
      estimate += initialEstimate * 0.3;
    }

    if (!readDocs) {
      estimate += initialEstimate * 0.3;
    }

    if (forgotTesting) {
      estimate += initialEstimate * 0.5;
    }

    if (!review) {
      estimate += initialEstimate * 0.2;
    }

    if (forgotSomething) {
      estimate += initialEstimate * 0.5;
    }

    estimate *= levelOfUnderstanding;

    return Math.ceil(estimate);
  })();

  const estimateLabel = getEstimateLabel(finalEstimate);

  return (
    <section
      className={css["Calculator"] + " shadow p-3 mb-5 bg-white rounded"}
    >
      <NumberInput
        value={initialEstimate}
        onChange={(e) => setInitialEstimate(Number(e.target.value))}
        id="initial-estimate"
        label="My estimate for doing the task (h):"
      />

      <Select
        id="level-of-understanding"
        value={levelOfUnderstanding}
        options={levelOfUnderstandingOptions}
        onChange={(e) => setLevelOfUnderstanding(Number(e.target.value))}
        label="My level of understanding about this task:"
      />

      <Checkbox
        id="have-docs"
        value={haveDocs}
        onChange={(e) => setHaveDocs(e.target.checked)}
        label="I have access to proper description, documentation or design to estimate this task."
      />

      <Checkbox
        id="read-docs"
        value={readDocs}
        onChange={(e) => setReadDocs(e.target.checked)}
        label="I have read the description, documentation or saw the design."
        disabled={!haveDocs}
      />

      <Checkbox
        id="forgot-testing"
        value={forgotTesting}
        onChange={(e) => setForgotTesting(e.target.checked)}
        label="I forgot to add testing to the list."
      />

      <Checkbox
        id="review"
        value={review}
        onChange={(e) => setReview(e.target.checked)}
        label="I never have to fix anything after a review."
      />

      {review && (
        <p>
          <a
            className="text-secondary"
            href="https://www.youtube.com/watch?v=r3hTwsvJV_A"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            https://www.youtube.com/watch?v=r3hTwsvJV_A
          </a>
        </p>
      )}

      <Checkbox
        id="forgot-something"
        value={forgotSomething}
        onChange={(e) => setForgotSomething(e.target.checked)}
        label="I probably forgot to think about something."
      />

      <div className="d-flex justify-content-between align-items-center pt-3">
        <div className="font-weight-bold">
          Final estimate: {finalEstimate}h{" "}
          {estimateLabel && (
            <span className={css["estimate-label"]}>{estimateLabel}</span>
          )}
        </div>

        <button className="btn btn-danger" onClick={reset}>
          Reset
        </button>
      </div>
    </section>
  );
};

export default memo(Calculator);
