import * as React from "react";
import { ChangeEvent, FC, memo, ReactNode } from "react";

type Props = {
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string | ReactNode;
  id: string;
  disabled?: boolean;
};

const Checkbox: FC<Props> = ({ value, onChange, label, id, disabled }) => (
  <div className="form-group form-check">
    <input
      type="checkbox"
      className="form-check-input"
      id={id}
      checked={value}
      onChange={onChange}
      disabled={disabled}
    />
    <label className="form-check-label" htmlFor={id}>
      {label}
    </label>
  </div>
);

export default memo(Checkbox);
