import * as React from "react";
import css from "./style.module.scss";
import Calculator from "../Calculator";

const App = () => (
  <section className={css["App"]}>
    <div className={css["content"]}>
      <h1 className="mb-5">Yo! It's time to estimate some tasks!</h1>
      <Calculator />
    </div>

    <div className={css["pepe"]} />
  </section>
);

export default App;
