import * as React from "react";
import { ChangeEvent, FC, memo } from "react";

type Props = {
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  id: string;
};

const NumberInput: FC<Props> = ({ value, onChange, label, id }) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <input
      type="number"
      className="form-control"
      id={id}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default memo(NumberInput);
