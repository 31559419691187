import * as React from "react";
import { ChangeEvent, FC, memo } from "react";

type Props = {
  value: number;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  id: string;
  options: { label: string | number; value: string | number }[];
};

const Select: FC<Props> = ({ value, onChange, label, id, options }) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <select value={value} className="form-control" id={id} onChange={onChange}>
      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

export default memo(Select);
